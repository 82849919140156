.musicPlayer {
  background-image: linear-gradient(rgba(51, 23, 132, 0.4), rgba(51, 23, 132, 0.4)), url("https://cdn.pixabay.com/photo/2017/07/13/20/11/turntable-2501761_960_720.jpg");
  height: 25rem;
  width: 25rem;
  background-size: cover;
  border-radius: 1rem;
  box-shadow: 0 2rem 2rem rgba(0, 0, 0, 0.2);
}

.albumAndArtistName {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding-top: 2rem;

  h4 {
    margin: 0;
    font-size: 1.5rem;
  }

  p {
    margin: 0.4rem 0 0;
  }
}

.musicProgress {
  margin-top: 10rem;
  display: flex;
  justify-content: center;

  .seekSlider {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 0.5rem;
    width: 90%;
    background: white;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border-radius: 1rem;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 1.5rem;
      height: 1.5rem;
      background: white;
      cursor: pointer;
      border-radius: 50%;
    }

    &:hover {
      opacity: 1.0;
    }
  }
}

.playerControls {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-top: 2rem;

  .prevTrack {
    margin-right: 3rem;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity .2s;

    &:hover {
      opacity: 1.0;
    }
  }

  .playOrPauseTrack {
    cursor: pointer;
    opacity: 0.8;
    transition: opacity .2s;

    &:hover {
      opacity: 1.0;
    }
  }

  .nextTrack {
    margin-left: 3rem;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity .2s;

    &:hover {
      opacity: 1.0;
    }
  }
}
