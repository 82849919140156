@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik+Gemstones&display=swap');

:root {
  --active-color: #CDCDE0;
}

body {
  background-color: var(--active-color);
  transition: background 0.4s ease;
  font-family: 'PT Sans Narrow', sans-serif;
}

.App {
  display: flex;
  height: calc(100vh - 1rem);
  align-items: center;
  //justify-content: center;
  flex-direction: column;
}

.colorBackdrop {
  background: linear-gradient(45deg, var(--active-color) 20%, transparent 100%)
  no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.colorBackdrop.playing {
  animation: colorChange 20s alternate infinite;
}

@keyframes colorChange {
  from {
    filter: hue-rotate(0deg);
  }
  to {
    filter: hue-rotate(360deg);
  }
}

.merryChristmas {
  font-family: 'Rubik Gemstones', cursive;
  font-size: 6rem;
  color: transparent;
  animation: effect 2s linear infinite;
}

@keyframes effect {
  0% {
    background: linear-gradient(#edabab, #F44336);
    -webkit-background-clip: text;
  }

  100% {
    background: linear-gradient(#ffffff, #ffffff);
    -webkit-background-clip: text;
  }
}
